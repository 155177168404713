<template>
  <div class="finance-invoice-review">
    <en-table-layout ref="enTableLayout" :tableData="tableData.data">
      <template slot="header">
        <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
          <el-tab-pane label="待审批" name="1"></el-tab-pane>
          <el-tab-pane label="已审批" name="2"></el-tab-pane>
        </el-tabs>
        <div style="padding: 10px 15px">
          <h3>客户账期申请流程：</h3>
          <div class="headerStep">
            <p class="stepImage">
              <img src="../../../assets/step/step1.png" alt="" />
              <span>销售人员从【客户档案】处提交账期申请</span>
            </p>
            <p class="stepLine">
              <span class="line"></span>
              <span class="arrow-right"></span>
            </p>
            <p class="stepImage">
              <img style="width: 66px" src="../../../assets/step/step2.png" alt="" />
              <span>部门进行初审（默认审核通过）</span>
            </p>
            <p class="stepLine">
              <span class="line"></span>
              <span class="arrow-right"></span>
            </p>
            <p class="stepImage">
              <img style="width: 92px" src="../../../assets/step/step3.png" alt="" />
              <span>财务人员进行复审，并设置账期信息</span>
            </p>
            <p class="stepLine">
              <span class="line"></span>
              <span class="arrow-right"></span>
            </p>
            <p class="stepImage">
              <img src="../../../assets/step/step4.png" alt="" />
              <span>客户账期审批通过</span>
            </p>
          </div>
        </div>
      </template>
      <template slot="toolbar">
        <el-form-item label="业务类型" class="col-auto">
          <el-select style="width: 110px" v-model="params.apply_type" placeholder="请选择" clearable>
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item v-if="activeName == '2'" label="审批状态" class="col-auto">
          <el-select style="width: 110px" v-model="params.apply_status" placeholder="请选择" clearable>
            <el-option v-for="item in optionsTypes" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>

        <el-form-item v-if="activeName == '1'" label="部门审批时间" class="col-auto">
          <el-date-picker v-model="order_time_range" type="daterange" style="width: 250px" range-separator="-"
            start-placeholder="开始日期" end-placeholder="结束日期" @change="handleDateChange" />
        </el-form-item>
        <el-form-item v-else label="财务审批时间" class="col-auto">
          <el-date-picker v-model="order_time_range" type="daterange" style="width: 250px" range-separator="-"
            start-placeholder="开始日期" end-placeholder="结束日期" @change="handleDateChangeTwo" />
        </el-form-item>
        <el-form-item>
          <el-input style="width: 290px" size="small" placeholder="请输入关键字进行搜索" v-model.trim="params.keyWord" clearable>
            <el-select v-model="key_word" slot="prepend" style="width: 110px">
              <el-option label="客户名称" value="customer_name"></el-option>
              <el-option label="提交人" value="create_user_name"></el-option>
              <!-- <el-option label="部门审批人" value="dept_audit_user_name"></el-option> -->
              <el-option v-if="activeName == '2'" label="财务复审人" value="finance_audit_user_name"></el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button native-type="submit" @click.stop.prevent="searchEvent" size="small" type="primary">搜索</el-button>
        </el-form-item>
      </template>

      <template slot="table-columns">
        <el-table-column prop="customer_name" show-overflow-tooltip label="所属客户名称" min-width="200" fixed="left" />
        <el-table-column prop="apply_type" show-overflow-tooltip label="业务类型" min-width="150" fixed="left">
          <template slot-scope="{row}">{{ row.apply_type == 1 ? '账期申请' : '账期信息变更' }}</template>
        </el-table-column>
        <el-table-column key="create_time" show-overflow-tooltip prop="create_time" label="提交时间" width="160"
          fixed="left">
          <template slot-scope="scope">{{ scope.row.create_time | unixToDate("yyyy-MM-dd hh:mm:ss") }}</template>
        </el-table-column>
        <el-table-column v-if="activeName == '1'" show-overflow-tooltip key="dept_audit_time" prop="dept_audit_time"
          label="部门审批时间" width="160">
          <template slot-scope="scope">{{ scope.row.create_time | unixToDate("yyyy-MM-dd hh:mm:ss") }}</template>
        </el-table-column>
        <el-table-column v-else key="finance_audit_time" show-overflow-tooltip prop="finance_audit_time" label="财务审批时间"
          width="160">
          <template slot-scope="scope">{{ scope.row.finance_audit_time | unixToDate("yyyy-MM-dd hh:mm:ss") }}</template>
        </el-table-column>
        <el-table-column prop="create_user_name" label="提交人" show-overflow-tooltip />
        <el-table-column key="bmspr" prop="dept_audit_user_name" label="部门审批人" width="200" show-overflow-tooltip>
          <template>——</template>
        </el-table-column>
        <el-table-column key="cwfsr" v-if="activeName == '2'" prop="finance_audit_user_name" label="财务复审人"
          width="170" />
        <el-table-column prop="apply_status" label="审批状态" width="150" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.apply_status | statusFilter }}
          </template>
        </el-table-column>
        <!--操作-->
        <el-table-column label="操作" width="150" fixed="right">
          <template slot-scope="{row}">
            <el-button v-if="activeName == '1'" size="mini" type="primary" @click="handleGoodsAudit(row)">
              审核
            </el-button>
            <el-button v-else size="mini" type="primary" @click="openDetail(row)">详情
            </el-button>
          </template>
        </el-table-column>
      </template>
      <el-pagination v-if="tableData" slot="pagination" @size-change="handlePageSizeChange"
        @current-change="handlePageCurrentChange" :current-page="tableData.page_no" :page-size="tableData.page_size"
        :page-sizes="MixinPageSizes" :layout="MixinTableLayout" background
        :total="tableData.data_total"></el-pagination>
    </en-table-layout>

    <!--审核 dialog-->
    <el-dialog-x-dialog title="审核操作" :close-on-click-modal="false" :close-on-press-escape="false" append-to-body
      width="500px" :visible.sync="examinationDialog">
      <el-form style="padding: 20px; margin-bottom: 30px;height: 500px;overflow-y: auto;" :model="goodsAuditForm"
        :rules="goodsAuditRules" ref="goodsAuditForm" label-width="110px">
        <el-form-item label="客户名称" prop="pass">
          {{ goodsAuditForm.customer_name }}
        </el-form-item>
        <el-form-item label="账期类型" prop="pass">
          有账期
        </el-form-item>
        <el-form-item label="账期信用额度" prop="account_period_credit_limit">
          <el-input style="width: 180px;" v-model="goodsAuditForm.account_period_credit_limit">
            <template slot="append">元</template>
          </el-input>
        </el-form-item>
        <el-form-item label="账期结算方式" prop="pass">
          <el-radio-group v-model="goodsAuditForm.account_period_settlement_method">
            <el-radio :label="1">按天</el-radio>
            <el-radio :label="2">按月</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="结算周期" prop="account_period_settlement_cycle">
          <el-input style="width: 180px;" v-model="goodsAuditForm.account_period_settlement_cycle">
            <template slot="append">{{ goodsAuditForm.account_period_settlement_method == 1 ? '天' : '月' }}</template>
          </el-input>
        </el-form-item>
        <el-form-item label="账期可延期天数" prop="account_period_extended_day">
          <el-input style="width: 180px;" v-model="goodsAuditForm.account_period_extended_day">
            <template slot="append">天</template>
          </el-input>
        </el-form-item>
        <el-form-item label="备注信息" prop="pass">
          <el-input type="textarea" :rows="5" placeholder="请输入不超100字的备注信息" maxlength="100"
            v-model.trim="goodsAuditForm.remark"></el-input>
        </el-form-item>
        <el-form-item label="审核" prop="pass">
          <el-radio-group v-model="goodsAuditForm.apply_status">
            <el-radio :label="3">通过</el-radio>
            <el-radio :label="4">拒绝</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="goodsAuditForm.apply_status === 4" label="拒因" prop="reason">
          <el-input v-model.trim="goodsAuditForm.apply_audit_reject_remark" type="textarea" :rows="5"
            placeholder="拒绝原因最多不超过100字。" maxlength="100" />
        </el-form-item>
      </el-form>
      <div style="position: fixed;right: 10px;bottom: 7px;">
        <el-button @click="examinationDialog = false" size="small">取 消</el-button>
        <el-button type="primary" @click="submitGoodsAuditForm" size="small">确 定</el-button>
      </div>
    </el-dialog-x-dialog>
    <!--账期申请详情 dialog-->
    <x-dialog :proxy="lookDialog">
      <el-form class="orderDetail" style="padding: 20px" :model="orderDetail" label-width="120px">
        <el-form-item label="客户名称" prop="pass">
          {{ orderDetail.customer_name }}
        </el-form-item>
        <el-form-item label="账期类型" prop="pass">
          {{ orderDetail.account_period ? '有账期' : '无账期' }}
        </el-form-item>
        <el-form-item label="账期信用额度" prop="pass">
          {{ orderDetail.account_period_credit_limit }}
        </el-form-item>
        <el-form-item label="账期结算方式" prop="pass">
          {{ orderDetail.account_period_settlement_method == 1 ? '按天' : '按月' }}
        </el-form-item>
        <el-form-item label="结算周期" prop="pass">
          {{ orderDetail.account_period_settlement_cycle }}
          {{ orderDetail.account_period_settlement_method == 1 ? '天' : '月' }}
        </el-form-item>
        <el-form-item label="账期可延期天数" prop="pass">
          {{ orderDetail.account_period_extended_day }}
        </el-form-item>
        <el-form-item label="备注信息" prop="pass">
          {{ orderDetail.remark }}
        </el-form-item>
        <el-form-item label="审核状态" prop="pass">
          {{ orderDetail.apply_status | statusFilter }}
        </el-form-item>
        <el-form-item label="拒绝原因" prop="pass" v-if="orderDetail.apply_status == 4">
          {{ orderDetail.apply_audit_reject_remark }}
        </el-form-item>
      </el-form>
    </x-dialog>
  </div>
</template>

<script>
import * as API_settingCustomer from '@/api/settingCustomer';
import EnTableLayout from "../../../../ui-components/TableLayout/src/main";
import XDialog from "@/components/x-dialog/x-dialog";
import { $xDialog } from "@/components/x-dialog/dialog.proxy";
import { RegExp } from '@/../ui-utils'
export default {
  name: "financialReviewList",
  components: { EnTableLayout, XDialog },
  data () {
    return {
      activeName: "1",

      //查看操作
      lookDialog: $xDialog.create({
        title: "账单申请",
        wrapperClass: "show-withdrawal-info",
        width: "40vw",
        disableCancel: true,
        disableConfirm: true,
      }),
      // 账单申请详情
      orderDetail: {},
      order_time_range: [],
      options: [
        {
          value: "",
          label: "全部",
        },
        {
          value: 1,
          label: "账期申请",
        },
        {
          value: 2,
          label: "账期信息变更",
        },
      ],
      optionsTypes: [
        {
          value: '',
          label: "全部",
        },
        {
          value: 3,
          label: "审批完成",
        },
        {
          value: 4,
          label: "财务复审拒绝",
        },
      ],
      // 列表loading状态
      loading: false,
      key_word: "customer_name",
      // 列表参数
      params: {
        page_no: 1,
        page_size: 20,
        apply_type: '',
        apply_status: 1,
        keyWord: "",
      },
      // 商品数据
      tableData: {},
      examinationDialog: false,
      // 审核商品 表单
      goodsAuditForm: {
        account_period_credit_limit: '',//账期信用额度
        account_period_settlement_method: 1,//账期结算方式
        account_period_settlement_cycle: '',//账期结算周期
        account_period_extended_day: '',//账期可延期天数
      },
      // 审核商品 表单校验
      goodsAuditRules: {
        account_period_credit_limit: [
          { required: true, message: '请填写账期额度', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (value > 1000000) {
                callback(new Error('输入最大值不超1000000'))
              } else if (!RegExp.money.test(value)) {
                callback(new Error("请输入正确的金额"));
              } else {
                callback()
              }
            }, trigger: 'blur'
          }
        ],
        account_period_settlement_cycle: [
          { required: true, message: '请填写结算周期', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (!/^[1-9]\d*$/.test(value)) {
                callback(new Error(`${this.goodsAuditForm.account_period_settlement_method == 1 ? '请输入3~1000的正整数' : '请输入1~100的正整数'}`));
              } else if (this.goodsAuditForm.account_period_settlement_method == 1 && (value > 1000 || value < 3)) {
                callback(new Error("请输入3~1000的正整数"));
              } else if (this.goodsAuditForm.account_period_settlement_method == 2 && value > 100) {
                callback(new Error("结算周期不能大于100"));
              } else {
                callback()
              }
            }, trigger: 'blur'
          }
        ],
        account_period_extended_day: [
          {
            validator: (rule, value, callback) => {
              if(value){
                if (!/^\d+$/.test(value)) {
                  callback(new Error("请输入正确的延期天数"));
                } else if (value > 20) {
                  callback(new Error("延期天数不能大于20天"));
                } else if (this.goodsAuditForm.account_period_settlement_cycle == '') {
                  callback(new Error("请先设置结算周期"));
                } else if (this.goodsAuditForm.account_period_settlement_method == 1 && value > Number(this.goodsAuditForm.account_period_settlement_cycle)) {
                  callback(new Error("延期天数需小于结算周期天数"));
                }else {
                callback()
              }
              }
               else {
                callback()
              }
            }, trigger: 'blur'
          }
        ]
      },
    };
  },
  filters: {
    statusFilter (status) {
      const statusMap = {
        1: "待财务复审",
        3: '财务复审通过',
        4: '财务复审拒绝'
      };
      return statusMap[status];
    },
  },
  mounted () {
    this.GET_GoodsAuditList();
  },
  methods: {
    handleClick () {
      this.params = {
        page_no: 1,
        page_size: 20,
        apply_type: '',
        apply_status: 1,
        keyWord: "",
      }
      this.tableData = { data: [] };
      this.order_time_range = [];
      if (this.activeName == "2") {
        this.params.apply_status = '';

        this.GET_GoodsAuditList();
      } else {
        this.params.apply_status = 1;
        this.GET_GoodsAuditList();
      }
      this.$nextTick((res) => {
        this.$refs.enTableLayout.$refs.table.doLayout();
      });
    },
    openDetail (row) {
      API_settingCustomer.getCustomerCapaDetail(row.id).then(res => {
        this.orderDetail = res
        this.lookDialog.display();
      })
    },
    /** 搜索事件触发 */
    searchEvent (data) {
      this.params.page_no = 1;
      this.GET_GoodsAuditList();
    },
    //格式化时间控件 部门审批时间
    handleDateChange () {
      if (
        this.order_time_range &&
        this.order_time_range.length
      ) {
        if (typeof this.order_time_range[0] === "string") {
          this.params.dept_audit_time_start =
            new Date(this.order_time_range[0]).getTime() / 1000;
          this.params.dept_audit_time_end =
            new Date(this.order_time_range[1]).getTime() / 1000 +
            86400;
        } else {
          this.params.dept_audit_time_start =
            this.order_time_range[0].getTime() / 1000;
          this.params.dept_audit_time_end =
            this.order_time_range[1].getTime() / 1000 + 86400;
        }
      } else {
        this.params.dept_audit_time_start = "";
        this.params.dept_audit_time_end = "";
      }
    },
    //格式化时间控件 财务审批时间
    handleDateChangeTwo () {
      if (
        this.order_time_range &&
        this.order_time_range.length
      ) {
        if (typeof this.order_time_range[0] === "string") {
          this.params.finance_audit_time_start =
            new Date(this.order_time_range[0]).getTime() / 1000;
          this.params.finance_audit_time_end =
            new Date(this.order_time_range[1]).getTime() / 1000 +
            86400;
        } else {
          this.params.finance_audit_time_start =
            this.order_time_range[0].getTime() / 1000;
          this.params.finance_audit_time_end =
            this.order_time_range[1].getTime() / 1000 + 86400;
        }
      } else {
        this.params.finance_audit_time_start = "";
        this.params.finance_audit_time_end = "";
      }
    },
    /** 分页大小发生改变 */
    handlePageSizeChange (size) {
      this.params.page_size = size;
      this.GET_GoodsAuditList();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange (page) {
      this.params.page_no = page;
      this.GET_GoodsAuditList();
    },

    /** 打开审核弹窗 */
    handleGoodsAudit (row) {
      API_settingCustomer.getCustomerCapaDetail(row.id).then(res => {
        this.goodsAuditForm = {
          account_period_credit_limit: '',//账期信用额度
          account_period_settlement_method: 1,//账期结算方式
          account_period_settlement_cycle: '',//账期结算周期
          account_period_extended_day: '',//账期可延期天数
        }
        this.goodsAuditForm = res;
        this.goodsAuditForm.apply_status = 3;//默认选中通过
        this.examinationDialog = true;
      })
    },
    /** 审核表单提交 */
    submitGoodsAuditForm () {

      this.$refs["goodsAuditForm"].validate((valid) => {
        if (valid) {
          let goodsAuditForm={
            ...this.goodsAuditForm
          }
          if(!goodsAuditForm.account_period_extended_day){
            goodsAuditForm.account_period_extended_day=0
          }
          API_settingCustomer.customerCapaApproval(goodsAuditForm).then((response) => {
            this.examinationDialog = false;
            this.$message.success("审核完成！");
            this.GET_GoodsAuditList();
          });
        }
      });

    },

    /** 获取列表数据 */
    GET_GoodsAuditList () {
      this.loading = true;
      let params = {
        ...this.params
      };
      params[this.key_word] = params.keyWord;
      delete params.keyWord;
      if (params.apply_status == '') params.apply_status = 6;
      if (this.activeName == "2") {
        params.order_by='finance'
        params.sort='desc'
      }
      API_settingCustomer.getCustomerPageList(params)
        .then((response) => {
          this.loading = false;
          this.tableData = response;
        })
        .catch(() => (this.loading = false));
    },
  },
};
</script>

<style lang="scss" scoped>
.goods-image {
  width: 50px;
  height: 50px;
}

.headerStep {
  display: flex;

  p {
    margin: 0;
  }

  .stepImage {
    display: flex;
    flex-direction: column;
    width: 200px;
    justify-content: center;
    align-items: center;

    img {
      width: 70px;
    }

    span {
      margin-top: 15px;
    }
  }

  .stepLine {
    display: flex;
    align-items: center;
    margin: 0 10px 39px;

    .line {
      display: inline-block;
      width: 80px;
      height: 4px;
      background: #333;
    }

    .arrow-right {
      display: inline-block;
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-bottom: 12px solid transparent;
      border-left: 10px solid #333;
    }
  }
}

.goods-picker-title {
  border-bottom: 1px solid #ccc;
  height: 51px;
  line-height: 51px;
  text-align: left;
  background-color: rgba(224, 228, 238, 1);
  font-weight: 600;
  border-radius: 5px;
}

.goods-picker-title span {
  background-color: #1a43a9;
  width: 4px;
  height: 14px;
  display: inline-block;
  margin-right: 14px;
  margin-bottom: 3px;
}

.el-radio {
  margin-top: 6px;
}

.fileName {
  width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
}

.orderDetail {
  .el-form-item {
    margin-bottom: 0;
  }
}
</style>
